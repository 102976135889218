import { Suspense, useEffect, useState } from "react"
import { PER_PAGE_PROJECT_OPTIONS } from "@contexts/FiltersProjectsContext"

import { PaginationProvider } from "@components/Pagination/contexts/PaginationContext"
import Pagination from "@components/Pagination"
import { FilterProjectsContextProvider } from "@components/Project/contexts/FilterProjectsContext"
import { FilterProjects } from "./components/FilterProjects"
import { ProjectComponents } from "@components/Project/components"
import { System } from "@design-system/index"
import { useSession } from "next-auth/react"
import useUser from "@hooks/user/useUser"
import { get, isNil } from "lodash"
import { isCurrentTrial } from "@helpers/user/isCurrentTrial"

const USER_WITH_FILTER_SYNCHRONIZE = [5020]

const Prevent2FA = () => {
  const { mainUser } = useUser()
  const [hide, setHide] = useState(true)

  useEffect(() => {
    const hideAlert = window.localStorage.getItem("hide_2fa_alert")

    if (!hideAlert) {
      setHide(false)
    }
  }, [])

  const createdAt = get(mainUser, "created_at")

  const isTrial = isCurrentTrial({
    userCreatedAt: String(createdAt),
  })

  if (!isNil(get(mainUser, "two_factor_method"))) {
    return
  }

  if (hide) {
    return
  }

  return (
    <System.Content.Body>
      <System.Alert.Attention
        color={isTrial ? "yellow" : "red"}
        title="Your 2FA isn't active yet."
      >
        <span>We strongly recommend </span>
        <System.Link href="/profile">
          enabling it now to enhance your account security.
        </System.Link>
        <System.Button.Default
          className="absolute right-3 top-3 z-50 cursor-pointer"
          onClick={() => {
            setHide(true)
            window.localStorage.setItem("hide_2fa_alert", "true")
          }}
        >
          <System.Svg.Cross className="h-4 w-4" />
        </System.Button.Default>
      </System.Alert.Attention>
    </System.Content.Body>
  )
}

const ProjectsLayout = () => {
  const { data: session } = useSession()

  return (
    <>
      <FilterProjectsContextProvider>
        <PaginationProvider
          keyStorage="table_projects"
          optionsPerPage={PER_PAGE_PROJECT_OPTIONS}
        >
          <Suspense fallback={null}>
            <Prevent2FA />
          </Suspense>
          <System.Content.Body>
            <FilterProjects
              withToggleSynchronize={USER_WITH_FILTER_SYNCHRONIZE.includes(
                //@ts-ignore
                session?.user?.user_id,
              )}
            />
          </System.Content.Body>
          <Suspense fallback={<System.SuspenseFallback.Table />}>
            <ProjectComponents.FetchMany.WithSearch>
              {({ projects, total }) => {
                return (
                  <>
                    <ProjectComponents.Table.Main
                      projects={projects}
                      total={total}
                    />
                    <System.Content.Body>
                      <Pagination />
                    </System.Content.Body>
                  </>
                )
              }}
            </ProjectComponents.FetchMany.WithSearch>
          </Suspense>
        </PaginationProvider>
      </FilterProjectsContextProvider>
    </>
  )
}

export default ProjectsLayout
