import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/24/outline"
import classNames from "classnames"
import { isEmpty, isNil } from "lodash"
import { System } from "@design-system/index"
import usePagination from "./hooks/usePagination"

interface Props {
  className?: string
}

function Pagination({ className }: Props) {
  const {
    page,
    perPage,
    maxPage,
    getStartPage,
    getPageLimitNumber,
    total,
    actions,
    optionsPerPage,
  } = usePagination()

  const classes = classNames(
    "w-full flex items-center justify-between",
    className,
  )

  return (
    <div className={classes}>
      <System.Text>
        Showing{" "}
        <span className="font-semibold text-gray-900">{getStartPage()}</span> to{" "}
        <span className="font-semibold text-gray-900">
          {getPageLimitNumber()}
        </span>{" "}
        of <span className="font-semibold text-gray-900">{total}</span> results
      </System.Text>

      <div className="flex items-center gap-8">
        {maxPage !== 1 && (
          <nav
            className="relative z-0 inline-flex -space-x-px rounded-md shadow-sm"
            aria-label="Pagination"
          >
            {maxPage !== 1 && (
              <button
                className="relative inline-flex items-center rounded-l-md border border-gray-300 bg-white px-2 py-2 text-sm font-medium text-gray-500 hover:bg-gray-50"
                onClick={() => {
                  if (page === 0) {
                    return
                  }
                  actions.setPage(page - 1)
                }}
              >
                <ChevronLeftIcon className="h-5 w-5" aria-hidden="true" />
              </button>
            )}
            {page !== 0 && (
              <button
                aria-current="page"
                className="relative inline-flex items-center border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-500 hover:bg-gray-50"
                onClick={() => {
                  actions.setPage(0)
                }}
              >
                1
              </button>
            )}
            {page + 1 !== maxPage && (
              <button
                aria-current="page"
                className="relative z-10 inline-flex items-center border border-indigo-500 bg-indigo-50 px-4 py-2 text-sm font-medium text-indigo-600"
              >
                {page + 1}
              </button>
            )}

            {(page === 0 || page + 1 === maxPage) && maxPage !== 1 && (
              <span className="relative inline-flex items-center border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700">
                ...
              </span>
            )}

            <button
              className={classNames(
                {
                  "z-10 border-indigo-500 bg-indigo-50 text-indigo-600":
                    page + 1 === maxPage,
                  "border-gray-300 bg-white text-gray-500 hover:bg-gray-50":
                    page + 1 !== maxPage,
                },
                "relative inline-flex items-center border px-4 py-2 text-sm font-medium",
              )}
              onClick={() => {
                actions.setPage(maxPage - 1)
              }}
            >
              {maxPage}
            </button>
            {maxPage !== 1 && (
              <button
                className="relative inline-flex items-center rounded-r-md border border-gray-300 bg-white px-2 py-2 text-sm font-medium text-gray-500 hover:bg-gray-50"
                onClick={() => {
                  if (page + 1 === maxPage) {
                    return
                  }
                  actions.setPage(page + 1)
                }}
              >
                <ChevronRightIcon className="h-5 w-5" />
              </button>
            )}
          </nav>
        )}
        {!isNil(optionsPerPage) && !isEmpty(optionsPerPage) && (
          <select
            id="perPage"
            name="perPage"
            value={perPage}
            onChange={(e) => actions.setPerPage(Number(e.target.value))}
            className="block cursor-pointer rounded-full border-gray-200 py-2 pl-3 pr-10 text-base transition focus-visible:border-gray-200 focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-indigo-600 focus-visible:ring-offset-2 sm:text-sm"
          >
            {optionsPerPage.map((item) => {
              return (
                <option value={item.value} key={item.value}>
                  {item.label}
                </option>
              )
            })}
          </select>
        )}
      </div>
    </div>
  )
}

export default Pagination
